import React from 'react';
import { Records } from '../general/Records';

const Form = () => (
  <div className="Forms-Container">
    <h4 className="display-4 text-center mb-4">
      <i className="fas fa-clipboard-list"></i> Formularios
    </h4>

    <Records />
  </div>
);

export default Form;