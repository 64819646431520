import React, { useEffect, useState } from "react";
import { getData, clean, getSimpleData, sendAudit, deleteEmptyJson, handleButtonClose, prepareSelect, prepareEmptyJson } from "../utilities/_functions";
import Axios from "axios";
import Select from 'react-select';

export const FormCongresistaSocial = (props) => {


  const [congresista, setCongresista] = useState([]);
  const [redSocial, setRedSocial] = useState([]);

  const [congresistaSelect, setCongresistaSelect] = useState(null);
  const [redSocialSelect, setRedSocialSelect] = useState(null);
  const [urlRed, setUrlRed] = useState(null);

  const dataToSend = {
    cng_rds_ID : congresistaSelect?.value, 
    rds_scl_ID : redSocialSelect?.value, 
    cng_rds_scl_url_red_scl : urlRed
  };
  const parametersQuery = ["congresistas", "redSocial"]

  useEffect(() => {
    parametersQuery.forEach(element => {
      getSimpleData(element).then(response => {
        if (element === "congresistas") {
          setCongresista(response);
        } else if (element === "redSocial") {
          setRedSocial(response);
        } 
      });
    });
  }, []);


  const submit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = prepareEmptyJson(dataToSend);
      await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/addCongresistaRedSocial`, prepareJson, {
        headers: headers
      });
      alert("Red social insertada correctamente");
      getData("5").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0]));
      });
      sendAudit(localStorage.getItem("user"), `Agrego red social a Congresista: ${dataToSend.cng_rds_ID}`);
      setCongresistaSelect(null);
      setRedSocialSelect(null);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };

  const submitEdit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = deleteEmptyJson(dataToSend);
      await Axios.put(`${process.env.REACT_APP_BACK_DOMAIN}/editCongresistaRedSocial/${props.data?.Id}`, prepareJson, {
        headers: headers
      });
      handleButtonClose();
      alert("Red social Editada correctamente");
      getData("5").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0])); 
      });
      sendAudit(localStorage.getItem("user"), `Modifico red social a Congresista: ${props.data?.Id}`);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };

  return (
    <>
      <form className="form" id="form" onSubmit={props.isModify && props.data ? submitEdit :submit}>
        <div className="Form-Contanier">
          {congresista ?
            <>
              <label htmlFor="register-congresista">Congresista *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(congresista, 0, 2)} defaultValue={{label:props.data?.Nombre}} onChange={setCongresistaSelect} isDisabled={true}/>
                :
                <Select value={congresistaSelect} options={prepareSelect(congresista, 0, 2)} onChange={setCongresistaSelect} />
              }
              <br></br>
            </>
            : null
          }

          {redSocial ?
            <>
              <label htmlFor="register-redSocial">Red social *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(redSocial)} defaultValue={{label:props.data?.RedSocial}} onChange={setRedSocialSelect} isDisabled={true}/>
                :
                <Select value={redSocialSelect} options={prepareSelect(redSocial)} onChange={setRedSocialSelect}/>
              }
              <br></br>
            </>
            : null
          }
          <label htmlFor="register-urlRed">URL red social (URL valida) *:</label>
          <input
            id="register-urlRed"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.URLRed : ""}
            onChange={(e) => setUrlRed(e.target.value)}
            autoComplete="off"
          />

          <input type="submit" value={props.isModify && props.data ? "Editar" :"Agregar"} />
        </div>
      </form>

    </>
  );
}