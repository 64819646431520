import React, { useEffect, useState } from "react";
import { getData, getSimpleData, clean, sendAudit, prepareSelect, deleteEmptyJson, prepareEmptyJson, handleButtonClose } from "../utilities/_functions";
import Axios from "axios";
import Select from 'react-select';

export const FormCongresista = (props) => {

  const [ciudad, setCiudad] = useState([]);
  const [estatuto, setEstatuto] = useState([]);
  const [lista, setLista] = useState([]);
  const [partido, setPartido] = useState([]);
  const [circunscripcion, setCircunscripcion] = useState([]);
  const [comision, setComision] = useState([]);

  const [nombre, setNombre] = useState(null);
  const [apellido, setApellido] = useState(null);
  const [trayectoria, setTrayectoria] = useState(null);
  const [uOcupacion, setUOcupacion] = useState(null);
  const [urlCongreso, setUrlCongreso] = useState(null);
  const [rPoder, setRPoder] = useState(null);
  const [urlFoto, setUrlFoto] = useState(null);
  const [fnacimiento, setFNacimiento] = useState(null);
  const [tProfecionales, setTProfesionales] = useState(null);
  const [ciudadSelect, setCiudadSelect] = useState(null);
  const [estatutoSelect, setEstatutoSelect] = useState(null);
  const [listaSelect, setListaSelect] = useState(null);
  const [partidoSelect, setPartidoSelect] = useState(null);
  const [circunscripcionSelect, setCircunscripcionSelect] = useState(null);
  const [comisionSelect, setComisionSelect] = useState(null);
  const [generoSelect, setGeneroSelect] = useState(null);
  const [curulSelect, setCurulSelect] = useState(null);
  const [activeSelect, setActiveSelect] = useState(null);

  const dataToSend = {
    cng_nmb: nombre,
    cng_pll: apellido,
    cng_sex: generoSelect?.value,
    cng_try_lsg: trayectoria,
    cng_ltm_cpc: uOcupacion,
    cng_url_cng: urlCongreso,
    cng_rlc_pdr: rPoder,
    cng_url_ftg: urlFoto,
    cng_fnc: fnacimiento,
    cng_ttl_prf: tProfecionales,
    cng_crl: curulSelect?.value,
    cdd_ID: ciudadSelect?.value,
    stt_ID: estatutoSelect?.value,
    lst_ID: listaSelect?.value,
    prt_avl_ID: partidoSelect?.value,
    crc_ID: circunscripcionSelect?.value,
    cms_cns_cng_ID: comisionSelect?.value,
    cn_ctv_cng: activeSelect?.value
  };
  const parametersQuery = ["ciudadFull", "estatuto", "lista", "partido", "circunscripcion", "comision"]


  const optionsCurul = [
    {value:"Representante a la Cámara", label:"Representante a la Cámara"},
    {value:"Senador", label:"Senador"},
  ];
  const optionsGenero = [
    {value:"Masculino", label:"Masculino"},
    {value:"Femenino", label:"Femenino"},
  ];
  const optionsActivo = [
    {value:"Activo", label:"Activo"},
    {value:"Ya no es congresista", label:"Ya no es congresista"},
  ];

  useEffect(() => {
    parametersQuery.forEach(element => {
      getSimpleData(element).then(response => {
        if (element === "ciudadFull") {
          setCiudad(response);
        } else if (element === "estatuto") {
          setEstatuto(response);
        } else if (element === "lista") {
          setLista(response);
        } else if (element === "partido") {
          setPartido(response);
        } else if (element === "circunscripcion") {
          setCircunscripcion(response);
        } else if (element === "comision") {
          setComision(response);
        }
      });
    });
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = prepareEmptyJson(dataToSend);
      await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/addCongresista`, prepareJson, {
        headers: headers
      });
      alert("Congresista insertado correctamente");
      getData("1").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0])); 
      });
      sendAudit(localStorage.getItem("user"), `Agrego Congresista: ${dataToSend.cng_nmb} ${dataToSend.cng_pll}`);
      setActiveSelect(null);
      setCircunscripcionSelect(null);
      setComisionSelect(null);
      setCiudadSelect(null);
      setEstatutoSelect(null);
      setGeneroSelect(null);
      setListaSelect(null);
      setPartidoSelect(null);
      setCurulSelect(null);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };
  const submitEdit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = deleteEmptyJson(dataToSend);
      await Axios.put(`${process.env.REACT_APP_BACK_DOMAIN}/editCongresista/${props.data?.Id}`, prepareJson, {
        headers: headers
      });
      handleButtonClose();
      alert("Congresista Editado correctamente");
      getData("1").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0])); 
      });
      sendAudit(localStorage.getItem("user"), `Modifico Congresista con Id: ${props.data?.Id}`);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };

  return (
    <>
      <form className="form" id="form" onSubmit={props.isModify && props.data ? submitEdit :submit}>
        <div className="Form-Contanier">
          <label htmlFor="register-nombre">Nombres *:</label>
          <input
            id="register-nombre"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Nombres : ""}
            onChange={(e) => {setNombre(e.target.value)}}
            autoComplete="off"
          />

          <label htmlFor="register-apellido">Apellidos *:</label>
          <input
            id="register-apellido"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Apellidos : ""}
            onChange={(e) => setApellido(e.target.value)}
            autoComplete="off"
          />

          <label htmlFor="register-sexo">Genero: </label>
          {
            props.isModify && props.data ?
            <Select options={optionsGenero} defaultValue={{label:props.data?.Sexo}} onChange={setGeneroSelect}/>
            :
            <Select value={generoSelect} options={optionsGenero} onChange={setGeneroSelect}/>
          }
          <br></br>

          <label htmlFor="register-trayectoria">Trayectoria Legislativa:</label>
          <input
            id="register-trayectoria"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Trayectoria : ""}
            onChange={(e) => setTrayectoria(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-uocupacion">Ultima Ocupacion:</label>
          <input
            id="register-uocupacion"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Ultima_Ocupacion : ""}
            onChange={(e) => setUOcupacion(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-urlcongreso">Url del congreso (Subir URL valida):</label>
          <input
            id="register-urlcongreso"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.URL_Congreso : ""}
            onChange={(e) => setUrlCongreso(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-rpoder">Relaciones de poder:</label>
          <input
            id="register-rpoder"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Relaciones_Poder : ""}
            onChange={(e) => setRPoder(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-urlfotografia">Url de fotografia (URL valida y subida a ARC):</label>
          <input
            id="register-urlfotografia"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Foto : ""}
            onChange={(e) => setUrlFoto(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-fechanacimiento">Año de nacimiento:</label>
          <input
            id="register-fechanacimiento"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Fecha_Nacimiento : ""}
            onChange={(e) => setFNacimiento(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-tprofesionales">Titulos Profesionales (Separados por punto. Ej: Pregado. Maestria. Doctorado.):</label>
          <input
            id="register-tprofesionales"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Titulo_Profesional : ""}
            onChange={(e) => setTProfesionales(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-curul">Curul *: </label>
          {
            props.isModify && props.data ?
            <Select options={optionsCurul} defaultValue={{label:props.data?.Camara}} onChange={setCurulSelect}/>
            :
            <Select value={curulSelect} options={optionsCurul} onChange={setCurulSelect}/>
          }
          <br></br>

          {ciudad ?
            <>
              <label htmlFor="register-ciudad">Ciudad: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(ciudad)} defaultValue={{label:props.data?.Ciudad}} onChange={setCiudadSelect}/>
                :
                <Select value={ciudadSelect} options={prepareSelect(ciudad)} onChange={setCiudadSelect}/>
              }
              <br></br>
            </>
            : null
          }

          {estatuto ?
            <>
              <label htmlFor="register-estatuto">Estatuto: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(estatuto)} defaultValue={{label:props.data?.Estatuto}} onChange={setEstatutoSelect}/>
                :
                <Select value={estatutoSelect} options={prepareSelect(estatuto)} onChange={setEstatutoSelect}/>
              }
              <br></br>
            </>
            : null
          }

          {lista ?
            <>
              <label htmlFor="register-lista">Lista *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(lista)} defaultValue={{label:props.data?.Lista}} onChange={setListaSelect}/>
                :
                <Select value={listaSelect} options={prepareSelect(lista)} onChange={setListaSelect}/>
              }
              <br></br>
            </>
            : null
          }

          {partido ?
            <>
              <label htmlFor="register-partido">Partido *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(partido)} defaultValue={{label:props.data?.Partido}} onChange={setPartidoSelect}/>
                :
                <Select value={partidoSelect} options={prepareSelect(partido)} onChange={setPartidoSelect}/>
              }
              <br></br>
            </>
            : null
          }
          {circunscripcion ?
            <>
              <label htmlFor="register-circunscripcion">Circunscripcion: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(circunscripcion)} defaultValue={{label:props.data?.Circunscripcion}} onChange={setCircunscripcionSelect}/>
                :
                <Select value={circunscripcionSelect} options={prepareSelect(circunscripcion)} onChange={setCircunscripcionSelect}/>
              }
              
              <br></br>
            </>
            : null
          }
          {comision ?
            <>
              <label htmlFor="register-comision">Comision *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(comision)} defaultValue={{label:props.data?.Comision}} onChange={setComisionSelect}/>
                :
                <Select value={comisionSelect} options={prepareSelect(comision)} onChange={setComisionSelect}/>
              }
              
              <br></br>
            </>
            : null
          }

          <label htmlFor="register-activo">¿Congresista activo? *:</label>
          {
            props.isModify && props.data ?
            <Select options={optionsActivo} defaultValue={{label:props.data?.Activo}} onChange={setActiveSelect}/>
            :
            <Select value={activeSelect} options={optionsActivo} onChange={setActiveSelect}/>
          }
          <br></br>

          <input type="submit" value={props.isModify && props.data ? "Editar" :"Agregar"} />
        </div>
      </form>

    </>
  );
}