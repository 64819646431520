import React, { useEffect, useState, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import { deleteRecord, getData, sendAudit } from "../utilities/_functions";
import Modal from "./Modal";

export const TableData = (props) => {
    const [columnsObject, setColumnsObject] = useState([]);
    const [showModal, setShowModal] = useState();
    const [dataToModify, setDataToModify] = useState();

    useEffect(() => {
        setColumnsObject([]);
        props.headerTable?.map(element => {
            const elementObject = {
                Header: `${element}`,
                accessor: `${element}`
            }
            setColumnsObject(columnsObject => [...columnsObject, elementObject]);
        });
    }, [props.headerTable]);
    useEffect(() => {
        document.addEventListener('hideModal', () => {
            setShowModal(false);
        });
    }, []);

    const deleteData = async (id, destination) => {
        try {
            let parameter = '';
            if (destination === '1') {
                parameter = 'Congresistas, por lo que tambien se elimino el congresista de las tablas: CongresistaRedSocial y voto';
            } else if (destination === '2') {
                parameter = 'Proyectos, por lo que tambien se elimino el proyecto de las tablas: ProyectoDebate y voto';
            } else if (destination === '3') {
                parameter = 'Votos';
            } else if (destination === '4') {
                parameter = 'ProyectoDebate';
            } else if (destination === '5') {
                parameter = 'CongresistasRedSocial';
            } else if (destination === '6') {
                parameter = 'Tema, por lo que tambien se eliminaron las siguientes tablas relacionadas: Proyecto, voto y ProyectoDebate';
            }
            const deleteResult = deleteRecord(id, destination);
            if (deleteResult) {
                alert("Eliminado correctamente");
                sendAudit(localStorage.getItem("user"), `Elimino registro con id: ${id} de la tabla ${parameter}`);
                getData(destination).then(response => {
                    props.dataTable(response);
                    props.dataTableHeader(Object.keys(response?.[0]));
                });
            }
            
        } catch (err) {
            alert("A ocurrido un error al intentar eliminar");
        }
    };

    const data = useMemo(() => props.records, [props.records]);
    const columns = useMemo(() => columnsObject, [columnsObject]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        usePagination
    );

    const { pageIndex, pageSize } = state;

    return (
        <>
            <div className={`Table-Container ${props.className ? `Table-Container_${props.className}` : ''}`}>
                <table {...getTableProps()} className="TableForms">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                    <td>
                                        <button className="btn" onClick={() => { setShowModal(true); setDataToModify(row?.original); }}>
                                            <i className="material-icons notranslate">edit</i>
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className="btn"
                                            onClick={() => deleteData(row?.original?.Id, props.destination)}
                                        >
                                            <i className="material-icons notranslate">delete</i>
                                        </button>
                                    </td>

                                </tr>

                            )

                        })}
                    </tbody>
                </table>
            </div>
            <div className={`ControlTable-Container ${props.className ? `ControlTable-Container_${props.className}` : ''}`}>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="ButtonNav">
                    {"<<"}
                </button>{" "}
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="ButtonNav">
                    Anterior
                </button>{" "}
                <button onClick={() => nextPage()} disabled={!canNextPage} className="ButtonNav">
                    Siguiente
                </button>{" "}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="ButtonNav">
                    {">>"}
                </button>{" "}
                <span>
                    Pagina{" "}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{" "}
                </span>
                <span>
                    | Ir a la pagina:{" "}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            gotoPage(pageNumber);
                        }}
                        style={{ width: "50px" }}
                    />
                </span>{" "}
                <select
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                >
                    {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Mostrar {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            {showModal && <Modal info={dataToModify} header={true} destination={props.destination} dataTable={props.dataTable} dataTableHeader={props.dataTableHeader}></Modal>}
        </>
    );
}