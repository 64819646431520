import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableData } from "./TableData"
import { FormCongresista } from "../forms/FormCongresista"
import { FormProyecto } from "../forms/FormProyecto"
import { FormVoto } from "../forms/FormVoto"
import { FormProyectoDebate } from "../forms/FormProyectoDebate"
import { FormCongresistaSocial } from "../forms/FormCongresistaSocial"
import { FormTema } from "../forms/FormTema"
import { getData } from "../utilities/_functions"
import { validateToken } from "../utilities/_functions";

// import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";



export const Records = () => {
    const [destination, setDestination] = useState('');
    const [records, setRecords] = useState([]);
    const [table, setTable] = useState(null);
    const [error, setError] = useState();
    const [headerTable, setHeaderTable] = useState([]);

    const history = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("auth-token")) {
            validateToken(localStorage.getItem("auth-token")).then(result => {
                if (result?.error) {
                    localStorage.setItem("auth-token", "");
                    localStorage.setItem("user", "");
                    history('/admin/login');
                }
            });
        }
        if (localStorage.getItem("auth-token") === '') history.push('/admin/login');
    })

    const getCurrentDate = (date) => {
        return new Date(Date.parse(date) - 1000 * 60 * 60 * 5).toISOString();
    }

    useEffect(() => {
        setTable(getForm(destination, records));
    }, [records, headerTable])

    const getRecords = async (e) => {
        try {
            if (destination) {
                getData(destination).then(response => {
                    setRecords(response);
                    setHeaderTable(Object.keys(response?.[0]));
                });
            }
            if (e) {
                e.preventDefault();
            }
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };



    const getForm = (destination, records) => {
        if (destination === '1') {
            return (
                <>
                    <FormCongresista
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                    <TableData
                        records={records}
                        headerTable={headerTable}
                        destination={destination}
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                </>
            );
        }
        if (destination === '2') {
            return (
                <>
                    <FormProyecto
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                    <TableData
                        records={records}
                        headerTable={headerTable}
                        destination={destination}
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                </>
            );
        }
        if (destination === '3') {
            return (
                <>
                    <FormVoto
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                    <TableData
                        records={records}
                        headerTable={headerTable}
                        className='small'
                        destination={destination}
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                </>
            );
        }
        if (destination === '4') {
            return (
                <>
                    <FormProyectoDebate
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                    <TableData
                        records={records}
                        headerTable={headerTable}
                        className='small'
                        destination={destination}
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                </>
            );
        }
        if (destination === '5') {
            return (
                <>
                    <FormCongresistaSocial
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                    <TableData
                        records={records}
                        headerTable={headerTable}
                        className='small'
                        destination={destination}
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                </>
            );
        }
        if (destination === '6') {
            return (
                <>
                    <FormTema
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                    <TableData
                        records={records}
                        headerTable={headerTable}
                        className='small'
                        destination={destination}
                        dataTable={setRecords}
                        dataTableHeader={setHeaderTable}
                    />
                </>
            );
        }
    }

    return (
        <div className="page">
            <div className="Records-Container">
                {error && (
                    <ErrorNotice message={error} clearError={() => setError(undefined)} />
                )}
                <form onSubmit={getRecords}>
                    <div className="row">
                        <div className="col s12">
                            <label htmlFor="plans">Formulario: </label>
                            <select name="plans" id="plans" onChange={(e) => setDestination(e.target.value)} defaultValue="">
                                <option value="" disabled>--Seleccione un Formulario--</option>
                                <option value="1">Congresista</option>
                                <option value="2">Proyecto</option>
                                <option value="3">Voto</option>
                                <option value="4">Proyecto Debate</option>
                                <option value="5">Congresista red social</option>
                                <option value="6">Tema</option>
                            </select>
                            <br></br>
                            <button type="submit" style={{ margin: "4px" }} name="action">
                                <i className="material-icons center notranslate">search</i>
                            </button>
                        </div>
                    </div>
                </form>
                <br></br>
            </div>
            {
                records && records.length && destination ? table : <p>No se encontraron resultados con los datos seleccionados</p>
            }
        </div>
    );
}