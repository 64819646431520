import React, { useEffect, useState } from "react";
import { getData, clean, getSimpleData, sendAudit, prepareSelect, deleteEmptyJson, handleButtonClose, prepareEmptyJson } from "../utilities/_functions";
import Axios from "axios";
import Select from 'react-select';

export const FormVoto = (props) => {


  const [congresista, setCongresista] = useState([]);
  const [proyecto, setProyecto] = useState([]);
  const [voto, setVoto] = useState([]);
  const [debate, setDebate] = useState([]);

  const [congresistaSelect, setCongresistaSelect] = useState(null);
  const [proyectoSelect, setProyectoSelect] = useState(null);
  const [votoSelect, setVotoSelect] = useState(null);
  const [debateSelect, setDebateSelect] = useState(null);

  const dataToSend = {
    cng_vot_ID : congresistaSelect?.value,
    pry_ID : proyectoSelect?.value,
    dbt_vot_ID : debateSelect?.value,
    tip_vot_ID : votoSelect?.value
  };


  const parametersQuery = ["congresistas", "proyectos", "tipoVoto", "debate"]

  useEffect(() => {
    parametersQuery.forEach(element => {
      getSimpleData(element).then(response => {
        if (element === "congresistas") {
          setCongresista(response);
        } else if (element === "proyectos") {
          setProyecto(response);
        } else if (element === "tipoVoto") {
          setVoto(response);
        } else if (element === "debate") {
          setDebate(response);
        }
      });
    });
  }, []);


  const submit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = prepareEmptyJson(dataToSend);
      await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/addCongresistaVoto`, prepareJson, {
        headers: headers
      });
      alert("Voto insertado correctamente");
      getData("3").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0]));
      });
      sendAudit(localStorage.getItem("user"), `Agrego voto: congresista-${dataToSend.cng_vot_ID} proyecto-${dataToSend.pry_ID} debate-${dataToSend.dbt_vot_ID}`);
      setCongresistaSelect(null);
      setProyectoSelect(null);
      setDebateSelect(null);
      setVotoSelect(null);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };

  const submitEdit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = deleteEmptyJson(dataToSend);
      await Axios.put(`${process.env.REACT_APP_BACK_DOMAIN}/editVoto/${props.data?.Id}`, prepareJson, {
        headers: headers
      });
      handleButtonClose();
      alert("Voto Editado correctamente");
      getData("3").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0]));
      });
      sendAudit(localStorage.getItem("user"), `Edito voto: ${props.data?.Id}`);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };

  return (
    <>
      <form className="form" id="form" onSubmit={props.isModify && props.data ? submitEdit : submit}>
        <div className="Form-Contanier">
          {congresista ?
            <>
              <label htmlFor="register-congresista">Congresista *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(congresista, 0, 2)} defaultValue={{ label: props.data?.Nombre }} onChange={setCongresistaSelect} isDisabled={true} />
                :
                <Select value={congresistaSelect} options={prepareSelect(congresista, 0, 2)} onChange={setCongresistaSelect} />
              }
              
              <br></br>
            </>
            : null
          }

          {proyecto ?
            <>
              <label htmlFor="register-proyecto">Proyecto *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(proyecto, 0, 0)} defaultValue={{ label: props.data?.Proyecto }} onChange={setProyectoSelect} isDisabled={true} />
                :
                <Select value={proyectoSelect} options={prepareSelect(proyecto, 0, 0)} onChange={setProyectoSelect} />
              }
              <br></br>
            </>
            : null
          }

          {debate ?
            <>
              <label htmlFor="register-debate">Debate *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(debate)} defaultValue={{ label: props.data?.debate }} onChange={setDebateSelect} isDisabled={true} />
                :
                <Select value={debateSelect} options={prepareSelect(debate)} onChange={setDebateSelect} />
              }
              
              <br></br>
            </>
            : null
          }

          {voto ?
            <>
              <label htmlFor="register-voto">Voto *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(voto)} defaultValue={{ label: props.data?.Voto }} onChange={setVotoSelect} />
                :
                <Select value={votoSelect} options={prepareSelect(voto)} onChange={setVotoSelect} />
              }
              
              <br></br>
            </>
            : null
          }


          <input type="submit" value={props.isModify && props.data ? "Editar" : "Agregar"} />
        </div>
      </form>

    </>
  );
}