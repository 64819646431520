import React, { useEffect, useState } from "react";
import { getData, getSimpleData, clean, sendAudit, prepareSelect, deleteEmptyJson, handleButtonClose, prepareDate, prepareEmptyJson } from "../utilities/_functions";
import Axios from "axios";
import Select from 'react-select';

export const FormProyecto = (props) => {


  const [tema, setTema] = useState([]);
  const [estado, setEstado] = useState([]);
  const [tipoProyecto, setTipoProyecto] = useState([]);
  const [comision, setComision] = useState([]);

  const [idProyecto, setIdProyecto] = useState(null);
  const [tVendedor, setTVendedor] = useState(null);
  const [tOficial, setTOficial] = useState(null);
  const [metadescripcion, setMetadescripcion] = useState(null);
  const [asiVoto, setAsiVoto] = useState(null);
  const [fRadicacion, setFRadicacion] = useState(null);
  const [autores, setAutores] = useState(null);
  const [ponentes, setPonentes] = useState(null);
  const [imagen, setImagen] = useState(null);
  const [tIniciativa, setTIniciativa] = useState(null);
  const [urlCongreso, setUrlCongreso] = useState(null);
  const [temaSelect, setTemaSelect] = useState(null);
  const [estadoSelect, setEstadoSelect] = useState(null);
  const [tipoProyectoSelect, setTipoProyectoSelect] = useState(null);
  const [comisionSelect, setComisionSelect] = useState(null);

  const dataToSend = {
    pry_ID: idProyecto,
    pry_ttl_vnd: tVendedor,
    pry_ttl_fcl: tOficial,
    pry_mtd: metadescripcion,
    pry_svt: asiVoto,
    pry_frd: fRadicacion,
    pry_trs: autores,
    pry_pnn: ponentes,
    pry_mgn_mnt: imagen,
    pry_tip_nct: tIniciativa,
    pry_url_mpt_pry: urlCongreso,
    tem_ID: temaSelect?.value,
    std_ID: estadoSelect?.value,
    tip_pry_ID: tipoProyectoSelect?.value,
    cms_cns_pry_ID: comisionSelect?.value
  };
  const parametersQuery = ["tema", "estado", "tipoProyecto", "comision"]; 

  useEffect(() => {
    parametersQuery.forEach(element => {
      getSimpleData(element).then(response => {
        if (element === "tema") {
          setTema(response);
        } else if (element === "estado") {
          setEstado(response);
        } else if (element === "tipoProyecto") {
          setTipoProyecto(response);
        } else if (element === "comision") {
          setComision(response);
        }
      });
    });
  }, []);


  const submit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = prepareEmptyJson(dataToSend);
      await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/addProyecto`, prepareJson, {
        headers: headers
      });
      alert("Proyecto insertado correctamente");
      getData("2").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0]));
      });
      sendAudit(localStorage.getItem("user"), `Agrego Proyecto: ${dataToSend.pry_ID}`);
      setTemaSelect(null);
      setEstadoSelect(null);
      setTipoProyectoSelect(null);
      setComisionSelect(null);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };

  const submitEdit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = deleteEmptyJson(dataToSend);
      await Axios.put(`${process.env.REACT_APP_BACK_DOMAIN}/editProyecto/${props.data?.Id}`, prepareJson, {
        headers: headers
      });
      handleButtonClose();
      alert("Proyecto editado correctamente");
      getData("2").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0]));
      });
      sendAudit(localStorage.getItem("user"), `Edito Proyecto: ${props.data?.Id}`);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };


  return (
    <>
      <form className="form" id="form" onSubmit={props.isModify && props.data ? submitEdit :submit}>
        <div className="Form-Contanier">
          <label htmlFor="register-idProyecto">Id del Proyecto *:</label>
          <input
            id="register-idProyecto"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Id : ""}
            onChange={(e) => setIdProyecto(e.target.value)}
            autoComplete="off"
            disabled={props.isModify && props.data ? true : false}
          />

          <label htmlFor="register-tVendedor">Titulo Vendedor *:</label>
          <input
            id="register-tVendedor"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Titulo : ""}
            onChange={(e) => setTVendedor(e.target.value)}
            autoComplete="off"
          />

          <label htmlFor="register-tOficial">Titulo Oficial *:</label>
          <input
            id="register-tOficial"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Titulo_Oficial : ""}
            onChange={(e) => setTOficial(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-metadescripcion">Metadescripcion :</label>
          <input
            id="register-metadescripcion"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Metadescripcion : ""}
            onChange={(e) => setMetadescripcion(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-tOficial">Asi Voto :</label>
          <input
            id="register-tOficial"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.AsiVoto : ""}
            onChange={(e) => setAsiVoto(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-date">Fecha de radicacion :</label>
          <input  
            id="register-date"
            type="date"
            defaultValue={props.isModify && props.data ?  props.data?.Fecha_Radicacion : ""}
            onChange={(e) => setFRadicacion(e.target.value)}
            autoComplete="off"
          />
          <br></br>
          <br></br>
          <label htmlFor="register-autores">Autores (Separados por coma) :</label>
          <input
            id="register-autores"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Autores : ""}
            onChange={(e) => setAutores(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-ponentes">Ponentes (Separados por coma) :</label>
          <input
            id="register-ponentes"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Ponentes : ""}
            onChange={(e) => setPonentes(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-imagen">Imagen (URL valida y subida a ARC) :</label>
          <input
            id="register-imagen"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Imagen : ""}
            onChange={(e) => setImagen(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-iniciativa">Tipo de iniciativa :</label>
          <input
            id="register-iniciativa"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Tipo_Iniciativa : ""}
            onChange={(e) => setTIniciativa(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-urlImportancia">URL Importancia del Proyecto (URL valida) :</label>
          <input
            id="register-urlImportancia"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Importancia_Proyecto : ""}
            onChange={(e) => setUrlCongreso(e.target.value)}
            autoComplete="off"
          />

          {tema ?
            <>
              <label htmlFor="register-tema">Tema *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(tema)} defaultValue={{label:props.data?.Tema}} onChange={setTemaSelect}/>
                :
                <Select value={temaSelect} options={prepareSelect(tema)} onChange={setTemaSelect}/>
              }
              <br></br>
            </>
            : null
          }

          {estado ?
            <>
              <label htmlFor="register-estado">Estado *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(estado)} defaultValue={{label:props.data?.Estado}} onChange={setEstadoSelect}/>
                :
                <Select value={estadoSelect} options={prepareSelect(estado)} onChange={setEstadoSelect}/>
              }
              <br></br>
            </>
            : null
          }

          {tipoProyecto ?
            <>
              <label htmlFor="register-Tproyecto">Tipo de Proyecto *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(tipoProyecto)} defaultValue={{label:props.data?.Tipo_Proyecto}} onChange={setTipoProyectoSelect}/>
                :
                <Select value={tipoProyectoSelect} options={prepareSelect(tipoProyecto)} onChange={setTipoProyectoSelect}/>
              }
              <br></br>
            </>
            : null
          }
          {comision ?
            <>
              <label htmlFor="register-comision">Comision *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(comision)} defaultValue={{label:props.data?.Comision_Constitucional}} onChange={setComisionSelect}/>
                :
                <Select value={comisionSelect} options={prepareSelect(comision)} onChange={setComisionSelect}/>
              }
              <br></br>
            </>
            : null
          }

          <input type="submit" value={props.isModify && props.data ? "Editar" :"Agregar"} />
        </div>
      </form>

    </>
  );
}