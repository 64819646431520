export function fillList(list, parameterId = 0, parameterText = 1) {
  const listSelect = [<option key={0} value={""}>{"--- Seleccione una opción ---"}</option>];
  list.map((element, key) => {
    if (element) {
      listSelect.push(
        <option key={Object.values(element)[parameterId]} value={Object.values(element)[parameterId]}>{Object.values(element)[parameterText]}</option>,
      );
    }
  });

  return listSelect;
}

export function prepareSelect(data, parameterId = 0, parameterText = 1){
  const options = [];
  if(data){
    data.map(el => {
      if (el) {
        options.push({value:Object.values(el)[parameterId], label:Object.values(el)[parameterText]});
      }
    })
  }
  return options;
}


export function getSimpleData(parameter) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${parameter}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'access-token': `${process.env.REACT_APP_TOKEN}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function getIdByName(parameter, name) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${parameter}/${name}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'access-token': `${process.env.REACT_APP_TOKEN}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function validateToken(token) {
  if (token) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users/tokenIsValid`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'x-auth-token': `${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  }

}

export function sendAudit(user, description) {
  if (user && description) {
    const dateActual = new Date();
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];
    const dateToSend = `${meses[dateActual.getMonth()]} ${dateActual.getDate()}, ${dateActual.getFullYear()}`
    const jsonToSend = {
      dtr_usr_mdf : user, 
      dtr_fch_ltm_ctl : dateToSend, 
      dtr_dsc : description
    }
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/addAuditoria`, {
        method: 'POST',
        body: JSON.stringify(jsonToSend),
        headers: {
          Accept: 'application/json',
          'access-token': `${localStorage.getItem("auth-token")}`,
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  }

}

export function getData(destination) {
  let parameter = '';
  if (destination === '1') {
    parameter = 'congresistasFullService';
  } else if (destination === '2') {
    parameter = 'proyectos';
  } else if (destination === '3') {
    parameter = 'voto';
  } else if (destination === '4') {
    parameter = 'proyectoDebate';
  } else if (destination === '5') {
    parameter = 'congresistasRedSocial';
  } else if (destination === '6') {
    parameter = 'tema';
  }
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${parameter}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        'access-token': `${process.env.REACT_APP_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
        //setHeaderTable(Object.keys(data?.[0]));
      })
      .catch(err => reject(err));
  });
}
export function clean() {
  let formulario = document.getElementById('form');
  formulario.reset();
}
export function deleteRecord(id, destination) {
  let dataTable = '';
  let dataId = '';
  let confirmationMessage = '';
  if (destination === '1') {
    dataTable = 'cn_cng';
    dataId = 'cng_ID';
    confirmationMessage = `Desea eliminar el congresista con id: ${id} ? Tenga en cuenta que esta accion eliminara el Congresista y sus datos relacionados de las tablas: Congresista, CongresistaRedSocial y Voto.`;
  } else if (destination === '2') {
    dataTable = 'cn_pry';
    dataId = 'pry_ID';
    confirmationMessage = `Desea eliminar el proyecto con id: ${id} ? Tenga en cuenta que esta accion eliminara el Proyecto y sus datos relacionados de las tablas: Proyecto, ProyectoDebate y Voto.`;
  } else if (destination === '3') {
    dataTable = 'cn_vot';
    dataId = 'vot_ID';
    confirmationMessage = `Desea eliminar el voto con id: ${id} ?`;
  } else if (destination === '4') {
    dataTable = 'cn_pry_dbt';
    dataId = 'pry_dbt_ID';
    confirmationMessage = `Desea eliminar el proyecto debate con id: ${id} ?`;
  } else if (destination === '5') {
    dataTable = 'cn_cng_rds_scl';
    dataId = 'cng_rds_scl_ID';
    confirmationMessage = `Desea eliminar el registro de red social con id: ${id} ?`;
  } else if (destination === '6') {
    dataTable = 'cn_tem';
    dataId = 'tem_ID';
    confirmationMessage = `Desea eliminar el registro de Tema con id: ${id} ? Tenga en cuenta que esta accion eliminara el Tema y sus datos relacionados de las tablas: Tema, Proyecto, ProyectoDebate y Voto.`;
  };
  if (window.confirm(confirmationMessage)) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/deleteDataRecord`, {
        method: "DELETE",
        body: JSON.stringify(
          {id:id, dataTable:dataTable, dataId:dataId}
        ),
        headers: {
          Accept: "application/json",
          'access-token': `${localStorage.getItem("auth-token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .catch(err => reject(err));
    });
  }else{
    return false;
  }
}

export function deleteEmptyJson(json) {
  for (var clave in json) {
    if(json[clave] == null){ 
      delete json[clave] 
    }
  }
  return json;
}

export function prepareEmptyJson(json) {
  for (var clave in json) {
    if(json[clave] == null){ 
      json[clave] = ''; 
    }
  }
  return json;
}

export function handleButtonClose(){
  const hideModal = new CustomEvent('hideModal', {
      detail: {
          name: 'modal',
          status: false,
      },
  });
  document.dispatchEvent(hideModal);
};

export function prepareDate(date){
  const arrayFecha = date.split("/");
  const fechaToSend = `${arrayFecha[2]}-${arrayFecha[1]}-${arrayFecha[0]}`

  return fechaToSend;
};


