import React, { useEffect, useState } from "react";
import { getData, getSimpleData, clean, sendAudit, prepareSelect, deleteEmptyJson, handleButtonClose, prepareEmptyJson } from "../utilities/_functions";
import Axios from "axios";
import Select from 'react-select';

export const FormProyectoDebate = (props) => {


  const [proyecto, setProyecto] = useState([]);
  const [debate, setDebate] = useState([]);
  const [estadoDebate, setEstadoDebate] = useState([]);
  
  const [proyectoSelect, setProyectoSelect] = useState(null);
  const [debateSelect, setDebateSelect] = useState(null);
  const [estadoDebateSelect, setEstadoDebateSelect] = useState(null);
  const [tVotoSelect, setTVotoSelect] = useState(null);
  const [fVotacion, setFVotacion] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [instancia, setInstancia] = useState(null);
  const [tCongresistas, setTCongresistas] = useState(null);
  const [tPresentes, setTPresentes] = useState(null);
  const [tAusentes, setTAusentes] = useState(null);
  const [tSi, setTSi] = useState(null);
  const [tNo, setTNo] = useState(null);

  const dataToSend = {
    pryt_ID: proyectoSelect?.value,
    dbt_pry_ID: debateSelect?.value,
    std_dbt_ID: estadoDebateSelect?.value,
    pry_dbt_tip_vot: tVotoSelect?.value,
    pry_dbt_fvtc: fVotacion,
    pry_dbt_dsc: descripcion,
    pry_dbt_nst: instancia,
    pry_dbt_ttl_cng: tCongresistas,
    pry_dbt_cng_prs: tPresentes,
    pry_dbt_cng_snt: tAusentes,
    pry_dbt_ttl_si: tSi,
    pry_dbt_ttl_no: tNo
  };
  const parametersQuery = ["proyectos", "debate", "estadoDebate"]

  useEffect(() => {
    parametersQuery.forEach(element => {
      getSimpleData(element).then(response => {
        if (element === "proyectos") {
          setProyecto(response);
        } else if (element === "debate") {
          setDebate(response);
        } else if (element === "estadoDebate") {
          setEstadoDebate(response);
        }
      });
    });
  }, []);

  const optionsTVotacion = [
    {value:"Nominal", label:"Nominal"},
    {value:"Ordinaria", label:"Ordinaria"},
    {value:"No sesionado", label:"No sesionado"},
    {value:"Retirado", label:"Retirado"},
  ];


  const submit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = prepareEmptyJson(dataToSend);
      await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/addProyectoDebate`, prepareJson, {
        headers: headers
      });
      alert("ProyectoDebate insertado correctamente");
      getData("4").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0]));
      });
      sendAudit(localStorage.getItem("user"), `Agrego info en Proyecto Debate: proyecto-${dataToSend.pryt_ID} debate-${dataToSend.dbt_pry_ID}`);
      setProyectoSelect(null);
      setDebateSelect(null);
      setEstadoDebateSelect(null);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };


  const submitEdit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Accept: 'application/json',
        'access-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json'
      }
      const prepareJson = deleteEmptyJson(dataToSend);
      await Axios.put(`${process.env.REACT_APP_BACK_DOMAIN}/editProyectoDebate/${props.data?.Id}`, prepareJson, {
        headers: headers
      });
      handleButtonClose();
      alert("ProyectoDebate editado correctamente");
      getData("4").then(response => {
        props.dataTable(response);
        props.dataTableHeader(Object.keys(response?.[0]));
      });
      sendAudit(localStorage.getItem("user"), `Edito info en Proyecto Debate: ${props.data?.Id}`);
      clean();
    } catch (err) {
      alert("Por favor verifique los datos ingresados");
    }
  };

  return (
    <>
      <form className="form" id="form" onSubmit={props.isModify && props.data ? submitEdit :submit}>
        <div className="Form-Contanier">
          {proyecto ?
            <>
              <label htmlFor="register-proyecto">Proyecto *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(proyecto, 0, 0)} defaultValue={{label:props.data?.Proyecto_ID}} onChange={setProyectoSelect} isDisabled={true}/>
                :
                <Select value={proyectoSelect} options={prepareSelect(proyecto, 0, 0)} onChange={setProyectoSelect}/>
              }
              <br></br>
            </>
            : null
          }

          {debate ?
            <>
              <label htmlFor="register-debate">Debate *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(debate)} defaultValue={{label:props.data?.Debate}} onChange={setDebateSelect} isDisabled={true}/>
                :
                <Select value={debateSelect} options={prepareSelect(debate)} onChange={setDebateSelect} />
              }
              <br></br>
            </>
            : null
          }

          {estadoDebate ?
            <>
              <label htmlFor="register-estadoDebate">Estado del debate *: </label>
              {
                props.isModify && props.data ?
                <Select options={prepareSelect(estadoDebate)} defaultValue={{label:props.data?.EstadoDebate}} onChange={setEstadoDebateSelect}/>
                :
                <Select value={estadoDebateSelect} options={prepareSelect(estadoDebate)} onChange={setEstadoDebateSelect}/>
              }
              
              <br></br>
            </>
            : null
          }
          <label htmlFor="register-tVotacion">Tipo de votación *:</label>
          {
            props.isModify && props.data ?
            <Select options={optionsTVotacion} defaultValue={{label:props.data?.TipoVotacion}} onChange={setTVotoSelect}/>
            :
            <Select value={tVotoSelect} options={optionsTVotacion} onChange={setTVotoSelect}/>
          }
          <br></br>
          <label htmlFor="register-dateDebate">Fecha de votación :</label>
          <input  
            id="register-dateDebate"
            type="date"
            defaultValue={props.isModify && props.data ? props.data?.FechaVotacion : ""}
            onChange={(e) => setFVotacion(e.target.value)}
            autoComplete="off"
          />
          <br></br>
          <br></br>
          <label htmlFor="register-descripcion">Descripción :</label>
          <input
            id="register-descripcion"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Descripcion : ""}
            onChange={(e) => setDescripcion(e.target.value) }
            autoComplete="off"
          />

          <label htmlFor="register-instancia">Instancia :</label>
          <input
            id="register-instancia"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.Instancia : ""}
            onChange={(e) => setInstancia(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-ttCongresistas">Total congresistas :</label>
          <input
            id="register-ttCongresistas"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.TotalCongresistas : ""}
            onChange={(e) => setTCongresistas(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-tcPresentes">Total congresistas presentes :</label>
          <input
            id="register-tcPresentes"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.TotalPresentes : ""}
            onChange={(e) => setTPresentes(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-tcAusentes">Total congresistas ausentes :</label>
          <input
            id="register-tcAusentes"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.TotalAusentes : ""}
            onChange={(e) => setTAusentes(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-totalSi">Total de votos si :</label>
          <input
            id="register-totalSi"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.TotalSi : ""}
            onChange={(e) => setTSi(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="register-totalNo">Total de votos no :</label>
          <input
            id="register-totalNo"
            type="text"
            defaultValue={props.isModify && props.data ? props.data?.TotalNo : ""}
            onChange={(e) => setTNo(e.target.value)}
            autoComplete="off"
          />

          <input type="submit" value={props.isModify && props.data ? "Editar" :"Agregar"} />
        </div>
      </form>

    </>
  );
}