import React, { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

export default function Home() {
    const { userData } = useContext(UserContext);
    const history = useNavigate();

    useEffect(() =>{
        if (!userData.user) history.push('/admin/login');
    })

    return (
        <div className="page">
            <h1>Servicio de Formularios Congreso a la mano</h1>
        </div>
    )
}