import React, { useState, useEffect } from 'react';
import { FormCongresista } from '../forms/FormCongresista';
import { FormProyecto } from '../forms/FormProyecto';
import { FormProyectoDebate } from '../forms/FormProyectoDebate';
import { FormVoto } from '../forms/FormVoto';
import { FormCongresistaSocial } from '../forms/FormCongresistaSocial';
import { handleButtonClose } from '../utilities/_functions';
import { FormTema } from '../forms/FormTema';

export const Modal = (props) => {
    const [modal, setModal] = useState(true);
    const [form, setForm] = useState(null);
    const [title, setTitle] = useState(null);
    const { info, footer, destination } = props;

    useEffect(() => {
        setForm(getForm(props.destination));
    }, []);


    const getForm = (destination) => {
        if (destination === '1') {
            setTitle(`Modificar congresista con id: ${info?.Id}`);
            return (
                <FormCongresista
                    data={info}
                    isModify={true}
                    dataTable={props.dataTable}
                    dataTableHeader={props.dataTableHeader}
                />
            );
        }
        if (destination === '2') {
            setTitle(`Modificar proyecto con id: ${info?.Id}`);
            return (
                <FormProyecto
                    data={info}
                    isModify={true}
                    dataTable={props.dataTable}
                    dataTableHeader={props.dataTableHeader}
                />
            );
        }
        if (destination === '3') {
            setTitle(`Modificar voto con id: ${info?.Id}`);
            return (
                <FormVoto
                    data={info}
                    isModify={true}
                    dataTable={props.dataTable}
                    dataTableHeader={props.dataTableHeader}
                />
            );
        }
        if (destination === '4') {
            setTitle(`Modificar proyecto debate con id: ${info?.Id}`);
            return (
                <FormProyectoDebate
                    data={info}
                    isModify={true}
                    dataTable={props.dataTable}
                    dataTableHeader={props.dataTableHeader}
                />
            );
        }
        if (destination === '5') {
            setTitle(`Modificar congresista red social con id: ${info?.Id}`);
            return (
                <FormCongresistaSocial
                    data={info}
                    isModify={true}
                    dataTable={props.dataTable}
                    dataTableHeader={props.dataTableHeader}
                />
            );
        }
        if (destination === '6') {
            setTitle(`Modificar Tema con id: ${info?.tem_ID}`);
            return (
                <FormTema
                    data={info}
                    isModify={true}
                    dataTable={props.dataTable}
                    dataTableHeader={props.dataTableHeader}
                />
            );
        }
    }

    const header = props.header ? (
        <div className={`Modal-HeaderContainer  ${footer ? 'Creditos-HeaderContainer' : ''}`}>
            <div></div>
            <label>{title}</label>
            <button
                className="btn-closeForm"
                style={{ margin: "4px" }}
                onClick={() => handleButtonClose()}
            >
                <i className="material-icons notranslate">close</i>
            </button>
        </div>
    ) : null;

    return (
        <div className={modal ? 'Modal' : 'Modal-hide'}>
            <div className={`Modal-Container ${footer ? 'Creditos-container' : ''}`}>
                {header}
                <div className='Modal-ModalContent'>
                    <div className='Modal-ModalForm' >
                        {form}
                    </div>
                </div>
                <div className='Modal-ModalButton'>
                    <div className='Modal-Button' onClick={() => handleButtonClose()}>Cancelar</div>
                </div>
            </div>
        </div>
    );
};

export default Modal;