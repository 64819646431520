import React, {useState } from "react";
import { getData, clean, sendAudit, deleteEmptyJson, handleButtonClose, prepareEmptyJson } from "../utilities/_functions";
import Axios from "axios";

export const FormTema = (props) => {


    const [tema, setTema] = useState(null);

    const dataToSend = {
        tem_nmb: tema,
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            const headers = {
                Accept: 'application/json',
                'access-token': `${localStorage.getItem("auth-token")}`,
                'Content-Type': 'application/json'
            }
            const prepareJson = prepareEmptyJson(dataToSend);
            await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/addTema`, prepareJson, {
                headers: headers
            });
            alert("Tema insertado correctamente");
            getData("6").then(response => {
                props.dataTable(response);
                props.dataTableHeader(Object.keys(response?.[0]));
            });
            sendAudit(localStorage.getItem("user"), `Agrego Tema: ${dataToSend.tem_nmb}`);
            clean();
        } catch (err) {
            alert("Por favor verifique los datos ingresados");
        }
    };

    const submitEdit = async (e) => {
        e.preventDefault();
        try {
            const headers = {
                Accept: 'application/json',
                'access-token': `${localStorage.getItem("auth-token")}`,
                'Content-Type': 'application/json'
            }
            const prepareJson = deleteEmptyJson(dataToSend);
            await Axios.put(`${process.env.REACT_APP_BACK_DOMAIN}/editTema/${props.data?.Id}`, prepareJson, {
                headers: headers
            });
            handleButtonClose();
            alert("Tema Editado correctamente");
            getData("6").then(response => {
                props.dataTable(response);
                props.dataTableHeader(Object.keys(response?.[0]));
            });
            sendAudit(localStorage.getItem("user"), `Edito Tema: ${props.data?.Id}`);
            clean();
        } catch (err) {
            alert("Por favor verifique los datos ingresados");
        }
    };

    return (
        <>
            <form className="form" id="form" onSubmit={props.isModify && props.data ? submitEdit : submit}>
                <div className="Form-Contanier">
                    <label htmlFor="register-tema">Tema *:</label>
                    <input
                        id="register-tema"
                        type="text"
                        defaultValue={props.isModify && props.data ? props.data?.Nombre : ""}
                        onChange={(e) => { setTema(e.target.value) }}
                        autoComplete="off"
                    />
                    <input type="submit" value={props.isModify && props.data ? "Editar" : "Agregar"} />
                </div>
            </form>

        </>
    );
}